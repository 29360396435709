const InfoCard = ({ title, text, icon, additionalClasses }) => {
  return (
    <div
      className={
        "info-card-container" +
        (additionalClasses ? " " + additionalClasses : "")
      }
    >
      <img src={icon.path} alt={icon.alt} className="info-card-icon" />
      <div className="info-card-content">
        <strong className="info-card-title">{title}</strong>
        <p className="info-card-text">{text}</p>
      </div>
    </div>
  );
};

export default InfoCard;
