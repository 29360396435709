import { useState } from "react";
import HamburgerIcon from "./general/HamburgerIcon";
import HamburgerMenu from "./home/HamburgerMenu";

const Header = ({ activateView, smallScreen }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollTo = (section) => {
    const sectionElement = document.querySelector(`.${section}`);
    sectionElement.scrollIntoView({ behavior: "smooth" });
  };

  const menuItems = [
    {
      name: "Home",
      onClick: smallScreen
        ? () => scrollTo("home-section-container")
        : () => activateView(0),
    },
    {
      name: "Services",
      onClick: smallScreen
        ? () => scrollTo("section-2")
        : () => activateView(1),
    },
    {
      name: "Accomplishments",
      onClick: smallScreen
        ? () => scrollTo("section-3")
        : () => activateView(2),
    },
    {
      name: "Technologies",
      onClick: smallScreen
        ? () => scrollTo("section-4")
        : () => activateView(3),
    },
    {
      name: "Testimonials",
      onClick: smallScreen
        ? () => scrollTo("section-5")
        : () => activateView(4),
    },
    {
      name: "Contact",
      onClick: smallScreen
        ? () => scrollTo("section-6")
        : () => activateView(5),
    },
  ];

  return (
    <>
      <header className="header">
        <div className="flex-center">
          <span
            className="logo"
            onClick={() => {
              activateView(0);
            }}
          >
            <img src="/images/logo.png" alt="logo" />
          </span>
          <p className="contact-details">
            <strong className="phone">+33 7 53 08 85 32</strong>
            <span className="slash-separator"> / </span>
            <strong className="email">contact@amine.business</strong>
          </p>
        </div>
        <span></span>
      </header>
      <HamburgerMenu
        active={menuOpen}
        closeMenu={() => {
          setMenuOpen(false);
        }}
        menuItems={menuItems}
      />
      <HamburgerIcon open={menuOpen} setOpen={setMenuOpen} />
    </>
  );
};

export default Header;
