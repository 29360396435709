const DoubleFrameCard = ({ title, icon, text, additionalClasses }) => {
  return (
    <div
      className={
        "double-frame-card " + (additionalClasses ? additionalClasses : "")
      }
    >
      <div className="double-frame-card-second-frame"> </div>
      <div className="double-frame-card-icon-container">
        <img
          src={icon?.path}
          alt={icon?.alt}
          className="double-frame-card-icon"
        />
      </div>
      <h2 className="double-frame-card-title">{title}</h2>
      <p className="double-frame-card-text">{text}</p>
    </div>
  );
};

export default DoubleFrameCard;
