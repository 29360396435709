const sendMessage = async (data) => {
  const response = await fetch(process.env.REACT_APP_API_URL + "/messages", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
};

const getUsersIp = async () => {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  return data?.ip;
};

const createSession = async () => {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sessions/current",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source: "client",
      }),
    }
  );
  const data = await response.json();
  setInterval(async () => {
    await fetch(
      process.env.REACT_APP_API_URL + "/sessions/" + data.id + "/active",
      {
        method: "PUT",
      }
    );
  }, 5000);

  return data;
};

export { sendMessage, getUsersIp, createSession };
