const HamburgerMenu = ({ active, closeMenu, menuItems }) => {
  return (
    <>
      <div
        className={
          "hamburger-menu-background " +
          (active ? "open-hamburger-menu-background" : "")
        }
        onClick={() => closeMenu()}
      >
        {/* <nav className={active ? "hamburger-menu" : ""}>
        <ul className="hamburger-menu-list">
        <li className="hamburger-menu-list-item">
        <span className="hamburger-menu-list-item">Home</span>
        </li>
        <li className="hamburger-menu-list-item">
        <span className="hamburger-menu-list-item">Services</span>
        </li>
        <li className="hamburger-menu-list-item">
        <span className="hamburger-menu-list-item">Achievements</span>
        </li>
        <li className="hamburger-menu-list-item">
        <span className="hamburger-menu-list-item">Skills</span>
        </li>
        <li className="hamburger-menu-list-item">
        <span className="hamburger-menu-list-item">Testimonials</span>
        </li>
        <li className="hamburger-menu-list-item">
        <span className="hamburger-menu-list-item">Contact</span>
        </li>
        </ul>
    </nav> */}
      </div>
      <nav
        className={"hamburger-menu " + (active ? " hamburger-menu-active" : "")}
      >
        <ul className="hamburger-menu-list">
          {menuItems.map((item, index) => (
            <li className="hamburger-menu-list-item" key={index}>
              <span
                className="hamburger-menu-list-item"
                onClick={() => {
                  closeMenu();
                  // wait 0.5 seconds before changing the active view
                  setTimeout(() => {
                    item.onClick();
                  }, 400);
                }}
              >
                {item.name}
              </span>
            </li>
          ))}
          {/* <li className="hamburger-menu-list-item">
            <span className="hamburger-menu-list-item">Home</span>
          </li>
          <li className="hamburger-menu-list-item">
            <span className="hamburger-menu-list-item">Services</span>
          </li>
          <li className="hamburger-menu-list-item">
            <span className="hamburger-menu-list-item">Achievements</span>
          </li>
          <li className="hamburger-menu-list-item">
            <span className="hamburger-menu-list-item">Skills</span>
          </li>
          <li className="hamburger-menu-list-item">
            <span className="hamburger-menu-list-item">Testimonials</span>
          </li>
          <li className="hamburger-menu-list-item">
            <span className="hamburger-menu-list-item">Contact</span>
          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default HamburgerMenu;
