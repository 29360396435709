import { useRef, useState } from "react";
import PointNav from "./PointNav";
import MainSection from "./MainSection";
import ServicesView from "./ServicesView";
import AboutMeView from "./AboutMeView";
import TechnologiesView from "./TechnologiesView";
import TestimonialsView from "./TestimonialsView";
import ContactView from "./ContactView";
import Navbar from "../Navbar";
import { useEffect } from "react";
const Main = () => {
  const [activeView, setActiveView] = useState(0);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [viewedSections, setViewedSections] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const secondSection = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   // detect arrow keys
  //   document.addEventListener("keydown", (e) => {
  //     clearTimeout(timout);
  //     timout = setTimeout(() => {
  //       console.log("activeView", activeView);
  //       if (e.key === "ArrowDown" || e.key === "ArrowRight") {
  //         console.log("arrow right");
  //         if (activeView < 5) {
  //           console.log("arrow right and activeView < 5");
  //           console.log("activeView", activeView);
  //           setActiveView(activeView + 1);
  //           setViewedSections({ ...viewedSections, [activeView + 1]: true });
  //         }
  //       } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
  //         console.log("arrow left");
  //         if (activeView > 0) {
  //           console.log("arrow left and activeView > 0");
  //           console.log("activeView", activeView);
  //           setActiveView(activeView - 1);
  //           setViewedSections({ ...viewedSections, [activeView - 1]: true });
  //         }
  //       }
  //     }, 300);
  //   });
  // }, []);

  const handleWheel = (e) => {
    clearTimeout(scrollTimeout);
    setScrollTimeout(
      setTimeout(() => {
        if (e.deltaY > 0 || e.deltaX > 0) {
          if (activeView < 5) {
            setActiveView(activeView + 1);
            setViewedSections({ ...viewedSections, [activeView + 1]: true });
          }
        } else {
          if (activeView > 0) {
            setActiveView(activeView - 1);
            setViewedSections({ ...viewedSections, [activeView - 1]: true });
          }
        }
      }, 300)
    );
  };

  const activateView = (index) => {
    setActiveView(index);
    setViewedSections({ ...viewedSections, [index]: true });
  };

  const handleScroll = (e) => {
    const divPosition = secondSection.current.getBoundingClientRect().top;
    const isDivAtTop = divPosition <= 0;
    if (isDivAtTop) {
      // div reached top of the window
    }
  };

  return (
    <>
      <Navbar activateView={activateView} smallScreen={windowWidth < 1000} />
      <div onScroll={handleScroll} onWheel={handleWheel}>
        <main className="home-section-container">
          <MainSection />
          <section
            className={
              "home-section hidden-section section section-2" +
              (activeView >= 1 || windowWidth < 1000 ? " show-section" : "")
            }
            ref={secondSection}
          >
            <ServicesView
              animate={
                // activeView >= 1
                viewedSections[1] || windowWidth < 1000 ? true : false
              }
            />
          </section>
          <section
            className={
              "home-section hidden-section section section-3" +
              (activeView >= 2 || windowWidth < 1000 ? " show-section" : "")
            }
          >
            <AboutMeView
              animate={
                // activeView >= 2
                viewedSections[2] || windowWidth < 1000 ? true : false
              }
              active={activeView >= 2 || windowWidth < 1000}
            />
          </section>
          <section
            className={
              "home-section hidden-section section section-4" +
              (activeView >= 3 || windowWidth < 1000 ? " show-section" : "")
            }
          >
            <TechnologiesView
              animate={
                // activeView >= 3
                viewedSections[3] || windowWidth < 1000 ? true : false
              }
            />
          </section>
          <section
            className={
              "home-section hidden-section section section-5" +
              (activeView >= 4 || windowWidth < 1000 ? " show-section" : "")
            }
          >
            <TestimonialsView
              animate={
                // activeView >= 4
                viewedSections[4] || windowWidth < 1000 ? true : false
              }
            />
          </section>
          <section
            className={
              "home-section hidden-section section section-6" +
              (activeView >= 5 || windowWidth < 1000 ? " show-section" : "")
            }
          >
            <ContactView
              animate={
                // activeView >= 5
                viewedSections[5] || windowWidth < 1000 ? true : false
              }
            />
          </section>
        </main>
        <span className="copyright">
          Designed and developed with love by Amine © 2023
        </span>
        <PointNav
          number={6}
          onClick={(index) => {
            setActiveView(index);
            setViewedSections({ ...viewedSections, [index]: true });
          }}
          currentIndex={activeView}
        />
      </div>
    </>
  );
};

export default Main;
