import InfoCard from "../general/InfoCard";

const TechnologiesView = ({ animate, active }) => {
  return (
    <div className="section-centent technologies-view-container">
      <h1
        className={
          "services-title hidden-animated-component-0-5 delayed-animation-0-4 " +
          (animate ? "apear-animation" : "")
        }
      >
        <span
          className={
            "primary-animatable-underline" +
            (animate ? " primary-animatable-underline-show-0-6" : "")
          }
        >
          Technologies
        </span>
        <span className="hollow-text-light"> I enjoy using</span>
      </h1>

      {/* <p className="technologies-sub-title">
        <span className="white-text bold">Lorem ipsum</span> dolor sit amet,
        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p> */}

      <div className={"technologies-card-container"}>
        <InfoCard
          title="React"
          text=" React is my go-to library for building modern, responsive user interfaces. Its component-based architecture and modular design make it easy to build hight quality complex applications."
          icon={{ path: "/images/react-logo.png", alt: "React Logo" }}
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-4 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <InfoCard
          title="Node.js"
          text="Node.js: Node.js is my preferred choice for building scalable and high-performance server-side applications. It's fast, lightweight, and perfect for building microservices."
          icon={{ path: "/images/nodejs-logo.svg", alt: "Node.js Logo" }}
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-45 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <InfoCard
          title="GO"
          text=" I love using Go for building high-performance applications that are easy to scale and maintain. Its simple syntax and built-in concurrency support make it a pleasure to work with."
          icon={{ path: "/images/go-logo.svg", alt: "Golang Logo" }}
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-5 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <InfoCard
          title="Python"
          text=" Python is my preferred language for data analysis, machine learning, and scientific computing. Its powerful libraries and frameworks make it easy to work with large datasets and complex algorithms."
          icon={{ path: "/images/python-logo.svg", alt: "Python Logo" }}
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-55 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <InfoCard
          title="C#"
          text="I love using C# because of its elegant syntax and powerful features. It's an ideal language for building desktop applications and web services.  I also enjoy using C# for game development because of its robustness and support for popular game engines like Unity.
          "
          icon={{ path: "/images/csharp-logo.svg", alt: "C# Logo" }}
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-6 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <InfoCard
          title="Docker"
          text="Docker has revolutionized the way I deploy and manage applications. Its lightweight containers allow me to isolate applications and their dependencies, making it easy to deploy and scale them across different environments."
          icon={{ path: "/images/docker-logo.svg", alt: "Docker Logo" }}
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-65 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
      </div>
    </div>
  );
};

export default TechnologiesView;
