import { useEffect } from "react";
import initializeParticles from "../../utils/particles/initializeParticles";
import "particles.js";

const MainView = () => {
  useEffect(() => {
    initializeParticles();
  }, []);
  return (
    <section className="home-section main-home-section" id="particles-js">
      <div className="main-home-section-content">
        <strong className="text-regular">Hello, I'm Amine </strong>
        <h1 className="main-title non-selectable ">
          I'm a&nbsp;
          <div className="professions-list">
            <strong className="profession-item">Software engineer</strong>
            <strong className="profession-item web-developer-w-space">
              Web Developer
            </strong>
            <strong className="profession-item mobile-developer-w-space">
              Mobile Developer
            </strong>
            <strong className="profession-item graphic-designer-w-space">
              Graphic Designer
            </strong>
            <strong className="profession-item">Software engineer</strong>
          </div>
        </h1>
        <p className="text-small color-gray">
          And I can make your ideas come to life
        </p>
      </div>
      {/* <span className="gradient-text"> This is a colorful text </span> */}
    </section>
  );
};

export default MainView;
