const HamburgerIcon = ({ open, setOpen }) => {
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <span
      className={"hamburger-icon " + (open ? "active-hamburger-icon" : "")}
      onClick={handleClick}
    >
      <span className="hamburger-icon-line"></span>
      <span className="hamburger-icon-line hamburger-icon-line-short"></span>
      <span className="hamburger-icon-line"></span>
    </span>
  );
};

export default HamburgerIcon;
