import DoubleFrameCard from "../general/DoubleFrameCard";

const ServicesView = ({ animate }) => {
  return (
    <div className="section-centent services-view-container">
      <h1
        className={
          "services-title hidden-animated-component-0-5 delayed-animation-0-4 " +
          (animate ? "apear-animation" : "")
        }
      >
        <span className="hollow-text">Some of my</span>{" "}
        <span
          className={
            "primary-animatable-underline" +
            (animate ? " primary-animatable-underline-show-0-6" : "")
          }
        >
          Services
        </span>
      </h1>
      <p
        className={
          "services-sub-title hidden-animated-component-0-5 delayed-animation-0-4 " +
          (animate ? "apear-animation" : "")
        }
      >
        With a diverse skill set and a passion for creating impactful digital
        experiences, I offer a variety of services to help you stand out in a
        crowded online landscape. Let's work together to create something
        extraordinary.
      </p>
      <div className={"services-card-container"}>
        <DoubleFrameCard
          title="Creative Design"
          icon={{
            path: "/images/graphic-design-icon.png",
            alt: "Graphic Design Icon",
          }}
          text="Eye-catching visuals that bring your brand to life and capture attention"
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-4 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <DoubleFrameCard
          title="Web & Mobile Development"
          icon={{
            path: "/images/code-icon.png",
            alt: "Code Icon",
          }}
          text="Fast, reliable websites and apps that perform seamlessly across all devices and platforms."
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-5 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
        <DoubleFrameCard
          title="SEO & Analytics"
          icon={{
            path: "/images/analytics-icon.png",
            alt: "Analytics Icon",
          }}
          text="Actionable insights and strategies to optimize your online presence and drive traffic."
          additionalClasses={
            "hidden-animated-component-0-5 delayed-animation-0-6 " +
            (animate ? "slide-from-bottom" : "")
          }
        />
      </div>
    </div>
  );
};

export default ServicesView;
