import "./styles/index.scss";
import Main from "./components/home/Main";
import { createSession } from "./utils/particles/apiCalls";
import { useEffect } from "react";

function App() {
  const createNewSession = async () => {
    await createSession();
  };
  useEffect(() => {
    createNewSession();
  }, []);
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
