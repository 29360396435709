import { useEffect, useState } from "react";

const CounterBox = ({
  title,
  value,
  animate,
  additionalClasses,
  percentage,
}) => {
  const [count, setCount] = useState(0);
  const [intervalTime, setIntervalTime] = useState(80);

  useEffect(() => {
    let intervalId;

    if (animate && count < value) {
      if (count >= value * 0.85) {
        setIntervalTime(500 / (value - value * 0.85));
      } else {
        setIntervalTime(800 / (value * 0.85));
      }

      intervalId = setInterval(() => {
        setCount((count) => Math.min(count + 1, value));
      }, intervalTime);
    }

    return () => clearInterval(intervalId);
  }, [animate, count, value, intervalTime]);

  return (
    <div
      className={"counter-box " + (additionalClasses ? additionalClasses : "")}
    >
      <div className="blury-background"></div>
      <p className="counter-box-counter">
        {count}
        {percentage && "%"}
      </p>
      <p>{title}</p>
    </div>
  );
};

export default CounterBox;
