const PointNav = ({ number, currentIndex, onClick }) => {
  return (
    <div className="point-nav">
      {Array.from({ length: number }, (_, index) => {
        return (
          <span
            key={index}
            className={`point-nav-item ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => onClick(index)}
          ></span>
        );
      })}
    </div>
  );
};

export default PointNav;
