import { useEffect, useState } from "react";
import Button from "../general/Button";
import TextArea from "../general/TextArea";
import TextInput from "../general/TextInput";
import { sendMessage } from "../../utils/particles/apiCalls";

const ContactForm = ({ active, additionalClasses }) => {
  const [messageSent, setMessageSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await sendMessage({ name, email, message });
    if (response.status === 201 || response.status === 200) {
      setName("");
      setEmail("");
      setMessage("");
      setSubmitMessage("Thank you, your message has been sent !");
    } else {
      setSubmitMessage("Something went wrong, please try again later.");
    }
    setLoading(false);
    setMessageSent(true);
  };

  useEffect(() => {
    setMessageSent(false);
    setSubmitMessage("");
  }, [active]);

  return (
    <div>
      <form
        className={
          "contact-form " + (additionalClasses ? additionalClasses : "")
        }
        onSubmit={handleSubmit}
      >
        <TextInput
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          required
        />
        <TextInput
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <TextArea
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Message"
          required
        />
        <div className="contact-form-button-cotainer">
          <Button type="submit" arrow={true} loading={loading}>
            Shoot
          </Button>
        </div>
      </form>
      <div className="contact-form-message-container ">
        <p
          className={
            "from-top contact-form-message  " + (messageSent ? " show" : "")
          }
        >
          {submitMessage}
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
