import CounterBox from "../general/ConterBox";
import { useState } from "react";
import { useEffect } from "react";

const AboutMeView = ({ animate, active }) => {
  const [projectsDelivered, setProjectsDelivered] = useState({
    count: 0,
    value: 26,
  });

  const [yearsOfExperience, setYearsOfExperience] = useState({
    count: 0,
    value: 4,
  });

  const backgroundImages = [
    {
      src: "images/futuristic-background-mobile.webp",
      media: "(max-width: 600px)",
    },
    {
      src: "images/futuristic-background.webp",
      media: "(min-width: 601px)",
    },
  ];

  useEffect(() => {
    const triggerCounters = () => {
      // counter should go up fast and then slow down when it reaches the value*

      // projects delivered
      if (projectsDelivered.count < projectsDelivered.value) {
        const projectsInterval =
          projectsDelivered.count < projectsDelivered.value * 0.9 // Acceleration phase
            ? 60 - projectsDelivered.count / (projectsDelivered.value * 0.09)
            : 200 -
              (projectsDelivered.count - projectsDelivered.value * 0.9) * 10; // Deceleration phase

        setTimeout(() => {
          setProjectsDelivered({
            ...projectsDelivered,
            count: projectsDelivered.count + 1,
          });
        }, projectsInterval);
      }

      if (yearsOfExperience.count < yearsOfExperience.value) {
        const yearsInterval =
          yearsOfExperience.count < yearsOfExperience.value * 0.9 // Acceleration phase
            ? 60 - yearsOfExperience.count / (yearsOfExperience.value * 0.09)
            : 200 -
              (yearsOfExperience.count - yearsOfExperience.value * 0.9) * 10; // Deceleration phase

        setTimeout(() => {
          setYearsOfExperience({
            ...yearsOfExperience,
            count: yearsOfExperience.count + 1,
          });
        }, yearsInterval);
      }
    };
    if (animate) {
      triggerCounters();
    }
  }, [animate, projectsDelivered, yearsOfExperience]);

  return (
    <div className="section-centent about-me-view-container">
      <picture>
        {backgroundImages.map(({ src, media }) => (
          <source key={src} srcSet={src} media={media} />
        ))}

        <img
          src={backgroundImages[0].src}
          alt="futuristic background"
          className={
            "about-me-background-image " + (animate ? "zoom-effect" : "")
          }
        />
      </picture>
      <div className="about-me-content">
        <h1
          className={"title-variant-1  " + (animate ? "apear-animation" : "")}
        >
          Achievements to Date
        </h1>

        <p
          className={
            "sub-title-white about-me-sub-title  " +
            (animate ? "apear-animation" : "")
          }
        >
          My goal is to provide my clients with top-notch products that meet
          their needs and exceed their expectations. By working closely with my
          clients throughout the development process, I am able to ensure that
          every aspect of the product is tailored to their specific
          requirements.
        </p>
        <div
          className={
            "counter-boxes-container " + (animate ? "apear-animation" : "")
          }
        >
          <CounterBox title="Projects delivered" value={26} animate={animate} />
          <CounterBox
            title="Years of experience
          "
            value={5}
            animate={animate}
          />
          <CounterBox
            title="Satisfied custumers"
            value={100}
            animate={animate}
            percentage={true}
          />
          <CounterBox
            title="Awards and certificates"
            value={12}
            animate={animate}
          />
        </div>
        {/* <Button additionalClasses={"about-me-sub-title "} arrow={true}>
          {" "}
          Learn More{" "}
        </Button> */}

        {/* <div className="companies-logos-container">
          <BrandCard
            brand={{ name: "IBM", logo: "/images/ibm.png" }}
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-4 " +
              (animate ? "slide-from-bottom" : "")
            }
          />
          <BrandCard
            brand={{ name: "podcast", logo: "/images/podcast.png" }}
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-45 " +
              (animate ? "slide-from-bottom" : "")
            }
          />
          <BrandCard
            brand={{ name: "cloudflare", logo: "/images/cloudflare.png" }}
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-5 " +
              (animate ? "slide-from-bottom" : "")
            }
          />
          <BrandCard
            brand={{ name: "vk", logo: "/images/vk.png" }}
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-55 " +
              (animate ? "slide-from-bottom" : "")
            }
          />
          <BrandCard
            brand={{ name: "ati", logo: "/images/ati.png" }}
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-6 " +
              (animate ? "slide-from-bottom" : "")
            }
          />
          <BrandCard
            brand={{ name: "astrazeneca", logo: "/images/astrazeneca.png" }}
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-65 " +
              (animate ? "slide-from-bottom" : "")
            }
          />
        </div> */}
      </div>
    </div>
  );
};

export default AboutMeView;
