import ReviewCard from "../general/ReviewCard";

const TestimonialsView = ({ animate }) => {
  return (
    <div className="section-centent testimonial-view-container">
      {/* <div className={"  " + (animate ? "reveal-text-animation" : "")}> */}
      <h3 className={"title-variant-2 before-after-animation-delay-0-5"}>
        TESTIMONIALS
      </h3>
      {/* </div> */}
      <h1
        className={
          "title-variant-1  " +
          (animate ? "apear-animation before-after-animation-delay-0-5" : "")
        }
      >
        What my clients say
      </h1>
      <div
        className={
          "testimonials-container hidden-animated-component-0-5 delayed-animation-0-4 " +
          (animate ? "apear-animation" : "")
        }
      >
        <ReviewCard
          title={"Great work !"}
          text={
            "Working with Amine was a game-changer for my business. He created a stunning app that perfectly captured my vision and provided me with valuable insights throughout the whole process. I couldn't be happier with the results!"
          }
          rating={5}
          author={{
            name: "Gabriel Granger",
            position: "Business Owner",
            image: "/images/man1.jpeg",
          }}
        />
        <ReviewCard
          title={"Highly recommend !"}
          text={
            "I was blown away by the website that Amine created for my business. It's not only beautiful, but also incredibly user-friendly. I've seen a significant increase in online traffic and sales since launching the site. Highly recommend!"
          }
          rating={5}
          author={{
            name: "Delphine Theriault",
            position: "Business Owner",
            image: "/images/woman1.jpg",
          }}
        />
      </div>
    </div>
  );
};

export default TestimonialsView;
