const ReviewCard = ({ title, text, rating, author, active }) => {
  return (
    <div className="review-card">
      <div className="review-card-author">
        <img
          className="review-card-avatar shine-from-left"
          src={author.image}
          alt={author.name}
        />
        <span className="review-card-author-name">{author.name}</span>
        <span className="review-card-author-position"> {author.position}</span>
      </div>
      <div className="review-card-content">
        <div className="review-card-title-container">
          <strong className="review-card-title">{title}</strong>
          <img
            className="review-card-quote-icon"
            src="/images/quote.png"
            alt="Quote"
          />
        </div>
        <p className="review-card-text light-gray-text">{text}</p>
        <div className="review-card-rating ">
          {Array.from(Array(rating).keys()).map((i) => (
            <img
              key={i}
              className="review-card-star-icon"
              src="/images/star.png"
              alt="Star"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
