import ContactForm from "./ContactForm";

const ContactView = ({ animate }) => {
  return (
    <div className="section-centent contact-view-container">
      <div className="contact-background-map"></div>
      <div className="contact-view-content">
        <div className="contact-view-upper-section">
          <div
            className={
              "contact-view-left-section hidden-animated-component-0-5 delayed-animation-0-4 " +
              (animate ? "slide-from-left" : "")
            }
          >
            <h1 className="contact-view-title title-1">
              <span className="hollow-text-light">Want to collaborate?</span>
              <strong className="">Get In Touch.</strong>
            </h1>
            <p className="light-gray-text">
              If you like what you see and want to discuss a potential project
              or job opportunity, I'd love to hear from you. Please use the
              contact form or email me directly to get in touch, and I'll be
              sure to respond as soon as possible.
            </p>
          </div>
          <ContactForm
            additionalClasses={
              "hidden-animated-component-0-5 delayed-animation-0-4 " +
              (animate ? "slide-from-right" : "")
            }
          />
        </div>
        {/* <div className="contact-info-container">
          <p className="contact-view-phone">Call: +33 7 53 08 85 32</p>
          <p
            className="contact-view-email gradient-text
          "
          >
            amine23.zn@gmail.com
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default ContactView;
