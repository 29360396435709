const TextArea = ({ label, ...rest }) => {
  return (
    <label
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      className=""
    >
      {label}
      <textarea className="text-input" {...rest} />
    </label>
  );
};

export default TextArea;
