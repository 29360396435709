const Button = ({ children, arrow, additionalClasses, loading, ...props }) => {
  return (
    <button
      className={
        "button-variant-1 arrow-button " +
        (additionalClasses ? additionalClasses : "") +
        (loading ? " button-disabled" : "")
      }
      disabled={loading}
      {...props}
    >
      {loading ? (
        <div className="button-loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        children
      )}
      {arrow && loading !== true && (
        <img
          className="button-arrow-icon"
          src="/images/right-arrow-light-gray.png"
          alt="arrow"
        />
      )}
    </button>
  );
};

export default Button;
